import { isDefined } from "@clipboard-health/util-ts";
import { UrlQueryStringReturnType } from "@src/app/utils/urlQueryHelper";
import { logEvent } from "@src/appV2/lib/analytics";
import { addDays, addMinutes, format as formatDate } from "date-fns";
import filter from "leo-profanity";

import { CUSTOM_FACILITY_SHIFT_FILTER_DATA_SEARCH_MODES } from "./constants";
import { FACILITY_OPEN_SHIFTS_DATE_RANGE_DAYS, SEARCH_MODE, USER_EVENTS } from "../../../constants";
import { ResponseDetailedRatings } from "../model";

const invalidWords = [
  "true",
  "false",
  "other",
  "others",
  "no thanks",
  "see above",
  "n/a",
  "no comment",
  "nothing",
  "none",
  "thanks",
];

const isValidReview = (reviewText: string) =>
  reviewText && typeof reviewText === "string" && !invalidWords.includes(reviewText.toLowerCase());

export const filterReviews = (reviews: ResponseDetailedRatings[]): ResponseDetailedRatings[] => {
  return reviews.map((reviewEntry) => {
    let validReview = "";
    if (isValidReview(reviewEntry?.review) && !isValidReview(reviewEntry?.otherReason)) {
      validReview = reviewEntry?.review;
    } else if (!isValidReview(reviewEntry?.review) && isValidReview(reviewEntry?.otherReason)) {
      validReview = reviewEntry?.otherReason;
    } else if (isValidReview(reviewEntry?.review) && isValidReview(reviewEntry?.otherReason)) {
      validReview = `${reviewEntry?.otherReason}. ${reviewEntry?.review}`;
    }

    try {
      const didCatchBadWord = filter.check(validReview);
      if (didCatchBadWord) {
        logEvent(USER_EVENTS.FACILITY_REVIEW_FILTERED);
      }
    } catch (e) {
      console.log(`An error occurred while trying to log FACILITY_REVIEW_FILTERED event: ${e}`);
    }
    return { ...reviewEntry, review: filter.clean(validReview) };
  });
};

export const getLateCancellationText = (
  lateCancellationPeriod: number,
  lateCancellationFeeHours: number
): string => {
  let lateCancellationPeriodText = "";
  let lateCancellationFeeHoursText = "";

  if (lateCancellationPeriod > 0 && lateCancellationFeeHours !== 0) {
    lateCancellationPeriodText = `Shifts cancelled by this facility within <strong>${lateCancellationPeriod} ${
      lateCancellationPeriod > 1 ? "hours" : "hour"
    }</strong> before shift start`;
    if (lateCancellationFeeHours > 0) {
      lateCancellationFeeHoursText = `will lead to a <strong>${lateCancellationFeeHours} ${
        lateCancellationFeeHours > 1 ? "hours" : "hour"
      }</strong> payout for the scheduled worker.`;
    }
  } else if (lateCancellationPeriod === 0 || lateCancellationFeeHours === 0) {
    lateCancellationPeriodText = `Shifts cancelled by this facility <strong>will not have any payout</strong> for the scheduled worker.`;
    lateCancellationFeeHoursText = "";
  }
  return `${lateCancellationPeriodText} ${lateCancellationFeeHoursText}`.trim();
};

export const getSentHomeChargesText = (sentHomeChargeHours: number): string => {
  let sentHomeChargeHoursText = "";
  if (sentHomeChargeHours > 0) {
    sentHomeChargeHoursText = `Shifts cancelled <strong>after the scheduled worker has arrived</strong> at the facility will lead to a <strong>${sentHomeChargeHours} ${
      sentHomeChargeHours > 1 ? "hours" : "hour"
    } payout.</strong>`;
  } else {
    sentHomeChargeHoursText = `Shifts cancelled <strong>after the scheduled worker has arrived</strong> at the facility <strong>will not have any payout</strong>.`;
  }
  return sentHomeChargeHoursText;
};

export const getStartDateFromParams = (
  params: UrlQueryStringReturnType,
  searchMode?: SEARCH_MODE
) => {
  const startDateTime =
    isDefined(searchMode) && CUSTOM_FACILITY_SHIFT_FILTER_DATA_SEARCH_MODES.includes(searchMode)
      ? params["shiftStartDate"]
      : params["shiftStart"] ?? formatDate(addMinutes(new Date(), 1), "yyyy-MM-dd");

  return startDateTime;
};

export const getEndDateFromParams = (
  params: UrlQueryStringReturnType,
  searchMode?: SEARCH_MODE
) => {
  const endDateTime =
    isDefined(searchMode) && CUSTOM_FACILITY_SHIFT_FILTER_DATA_SEARCH_MODES.includes(searchMode)
      ? params["shiftEndDate"]
      : params["shiftEnd"] ??
        params["shiftStart"] ??
        formatDate(addDays(new Date(), FACILITY_OPEN_SHIFTS_DATE_RANGE_DAYS), "yyyy-MM-dd");
  return endDateTime;
};
