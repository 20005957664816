import "./style.scss";
import { formatDate } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonButton,
  IonChip,
  IonCol,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  isPlatform,
} from "@ionic/react";
import CloseIcon from "@mui/icons-material/Close";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Box, IconButton, Stack } from "@mui/material";
import { CancellationPayout } from "@src/app/dayView/facilityDetails/facilityCancellationPayout";
import { FacilityOpenShifts } from "@src/app/dayView/facilityDetails/facilityOpenShifts";
import { FacilityPhotos } from "@src/app/dayView/facilityDetails/facilityPhotos";
import { useActiveScreenNameDeprecated } from "@src/app/routing/hooks/useActiveScreenNameDeprecated";
import { environmentConfig } from "@src/appV2/environment";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { useFacilityPhotos } from "@src/appV2/Facilities/api/useFacilityPhotos";
import { useFacilityProfile } from "@src/appV2/Facilities/api/useFacilityProfile";
import { useIsExtraTimePayEnabled } from "@src/appV2/Facilities/api/useIsExtraTimePayEnabled";
import { ExtraTimePayCard } from "@src/appV2/Facilities/components/ExtraTimePayCard";
import { FacilityDetailsCollapse } from "@src/appV2/Facilities/components/FacilityDetailsCollapse";
import { isMandatoryBreakPolicyNote } from "@src/appV2/Facilities/types";
import { calculateDistanceToFacilityInMiles } from "@src/appV2/Facilities/utils/index";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useGoogleSignedUrl } from "@src/appV2/Google/useGoogleSignedUrl";
import { AppBarHeader, PageWithHeader } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import {
  DeprecatedRatingsSummaryWorkplaceDetails,
  ReviewSummaryWorkplaceDetails,
} from "@src/appV2/Reviews/components/WorkplaceDetails";
import { useGetStreaksSettings } from "@src/appV2/Streaks/api";
import { useStreaksEnabledForHcp } from "@src/appV2/Streaks/api/featureFlagHooks";
import { FacilityStreakInfoCard } from "@src/appV2/Streaks/components/FacilityStreakInfoCard";
import { useHCPStreakData } from "@src/appV2/Streaks/hooks";
import { getCurrentActiveStreaks, getPayBoostActiveStreaks } from "@src/appV2/Streaks/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useGetWorkplaceQuiz } from "@src/appV2/WorkplaceQuiz/api/useGetWorkplaceQuiz/useGetWorkplaceQuiz";
import { useIsQuizWorkersOnWorkplaceRulesFeatureEnabled } from "@src/appV2/WorkplaceQuiz/utils/useIsQuizWorkersOnWorkplaceRulesEnabled";
import { WorkplaceImportantInformation } from "@src/appV2/WorkplaceQuiz/WorkplaceImportantInformation/WorkplaceImportantInformation";
import CheckedInInstruction from "@src/assets/images/facilityDetails/check-in-instructions.svg";
import ChevronDown from "@src/assets/images/facilityDetails/chevron-compact-down.svg";
import ExclamationMark from "@src/assets/images/facilityDetails/exclamation-mark.svg";
import FreeCancellation from "@src/assets/images/facilityDetails/free-cancellation.svg";
import MapDirections from "@src/assets/images/facilityDetails/map-directional.svg";
import { SEARCH_MODE, USER_EVENTS } from "@src/constants";
import { FacilityNoteIdentifier } from "@src/constants/facility";
import { usCountyCode } from "@src/constants/phone";
import { MessageString } from "@src/constants/strings";
import { Facility, Shift } from "@src/lib/interface";
import { formatPhone } from "@src/lib/utils";
import { getCheckInInstructionsObj, getNotesByCategory } from "@src/utils/facility";
import { home } from "ionicons/icons";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import pluralize from "pluralize";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { HcfProfileSource } from "./model";

const STRING_TO_DELETE = "(visible to worker)";

interface FacilityDetailsProps {
  facility: Facility;
  onClose: () => void;
  shift?: Shift;
  showHeader?: boolean;
  displayExtraTimePayCard?: boolean;
  hcfProfileSource?: HcfProfileSource;
  searchMode?: SEARCH_MODE;
}

const FacilityDetails: FC<FacilityDetailsProps> = ({
  facility,
  onClose,
  shift,
  showHeader = true,
  displayExtraTimePayCard = false,
  hcfProfileSource,
  searchMode,
}) => {
  const deprecatedScreenName = useActiveScreenNameDeprecated();
  const facilityOpenShiftsRef = useRef<HTMLDivElement>(null);
  const worker = useDefinedWorker();
  const [overlay, setOverlay] = useState<string>("");
  const {
    data: facilityPhotos,
    isLoading: isLoadingPhotos,
    isSuccess: isSuccessPhotos,
  } = useFacilityPhotos(
    { facilityId: facility.userId ?? "" },
    { enabled: isDefined(facility.userId) }
  );

  const workplaceUserId = facility.userId ?? shift?.facility?.userId;
  const workplaceName = facility.name ?? shift?.facility?.name;
  const workplaceType = facility.type ?? shift?.facility?.type;

  const workPlaceDetails =
    isDefined(workplaceUserId) && isDefined(workplaceName) && isDefined(workplaceType)
      ? { userId: workplaceUserId, name: workplaceName, type: workplaceType }
      : undefined;

  const isExtraTimePayEnabled = useIsExtraTimePayEnabled(facility);
  const etpEventOptions = useMemo(() => {
    return {
      extra_time_pay_feature_flag_enabled: "yes",
      extra_time_pay_enabled: isExtraTimePayEnabled ? "yes" : "no",
      showing_extra_time_pay_icon: "yes",
    };
  }, [isExtraTimePayEnabled]);
  const ldFlags = useCbhFlags();
  const showCancellationPayout = ldFlags[CbhFeatureFlag.SHOW_CANCELLATION_PAYOUT];
  const facilityTypeIconsEnabledMap: Record<string, boolean> =
    ldFlags[CbhFeatureFlag.FACILITY_TYPE_SHIFT_ICONS] ?? {};

  const facilityTypeShiftIconsEnabled = facility?.type
    ? facilityTypeIconsEnabledMap[facility.type] ?? false
    : false;

  const {
    mutateAsync: signFacilityStreetImgUrl,
    data: facilityStreetImgUrl,
    isLoading: isLoadingFacilityStreetImgUrl,
  } = useGoogleSignedUrl();

  useEffect(() => {
    logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, etpEventOptions);
  }, [etpEventOptions]);

  useEffect(() => {
    const facilityCoordinates: number[] = facility?.geoLocation?.coordinates ?? [];
    // It is possible that coordinates do not exist (e.g. when facilities are in onboarding stage)
    if (facilityCoordinates.length < 2) {
      return;
    }
    const googleStreetMapViewApiUrl = "https://maps.googleapis.com/maps/api/streetview";
    const streetImageUrl = `${googleStreetMapViewApiUrl}?size=500x500&location=${facilityCoordinates[1]},${facilityCoordinates[0]}&key=${environmentConfig.REACT_APP_GOOGLE_MAP_API}`;

    signFacilityStreetImgUrl({ url: streetImageUrl });
  }, [facility, signFacilityStreetImgUrl]);

  const distanceInMiles = calculateDistanceToFacilityInMiles(worker, facility);

  const groupStyle = {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };

  const onCheckInInfoPanelClick = (isOpen: boolean) => {
    if (isOpen) {
      logEvent(USER_EVENTS.VIEWED_CHECK_IN_INSTRUCTIONS);
    }
  };

  const onImportantInformationPanelClick = (isOpen: boolean) => {
    if (isOpen) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_IMPORTANT_INFORMATION);
    }
  };
  const onExtraTimePayPanelClick = (isOpen: boolean) => {
    if (isOpen) {
      logEvent(USER_EVENTS.VIEWED_EXTRA_TIME_PAY_PANEL, etpEventOptions);
    }
  };

  const onCancellationPayoutPanelClick = (isOpen: boolean) => {
    if (isOpen) {
      logEvent(USER_EVENTS.VIEWED_CANCELLATION_PAYOUT_PANEL);
    }
  };

  const onMapsClick = (overlay: string) => () => {
    logEvent(USER_EVENTS.TAPPED_OPEN_MAPS);
    if (isPlatform("ios")) {
      setOverlay(overlay);
    } else {
      openGoogleMaps();
    }
  };

  const handleCancel = () => {
    setOverlay("");
  };

  const openGoogleMaps = () => {
    const { name, fullAddress, geoLocation } = facility;
    logEvent(USER_EVENTS.TAPPED_GOOGLE_MAPS);

    const baseUrl = "https://www.google.com/maps/search/?api=1";
    const query =
      isDefined(name) && isDefined(fullAddress)
        ? `${name} ${fullAddress?.formatted}`
        : `${geoLocation?.coordinates[1]},${geoLocation?.coordinates[0]}`;

    window.open(encodeURI(`${baseUrl}&query=${query}`), "_blank");
  };

  const openAppleMaps = () => {
    const { name, fullAddress, geoLocation } = facility;
    logEvent(USER_EVENTS.TAPPED_APPLE_MAPS);

    const baseUrl = "http://maps.apple.com";
    const query = `address=${fullAddress?.formatted}&ll=${geoLocation?.coordinates[1]},${
      geoLocation?.coordinates[0]
    }${isDefined(name) ? `&q=${name}` : ""}`;

    window.open(encodeURI(`${baseUrl}?${query}`), "_blank");
  };

  const openMapsOverlay = () => (
    <div
      id="overlay-section"
      data-testid="overlay-section"
      style={{
        zIndex: 300,
        position: "fixed",
        bottom: "1rem",
        left: 0,
        right: 0,
        top: "5rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IonItemGroup style={groupStyle}>
        <div className="ion-text-center ion-margin open-maps-container">
          <IonText data-testid="apple-maps" onClick={openAppleMaps}>
            Open in Apple Maps
          </IonText>
          <hr />
          <IonText onClick={openGoogleMaps}>Open in Google Maps</IonText>
        </div>
        <div className="ion-text-center ion-margin open-maps-cancel-btn">
          <IonText onClick={handleCancel}>Cancel</IonText>
        </div>
      </IonItemGroup>
    </div>
  );

  const stripVisibleToHCPSuffix = (instruction) => {
    instruction.category = instruction.category.trim();
    instruction.category = instruction.category.endsWith(" (Visible to HCP)")
      ? instruction.category.replace(" (Visible to HCP)", "")
      : instruction.category;
    return instruction;
  };

  const getPresentCheckInInstructions = (checkInInstructionsObj) => {
    const checkInInstructionsArr = [
      checkInInstructionsObj.CHECK_IN_INST_PARKING,
      checkInInstructionsObj.CHECK_IN_INST_ENTRANCE,
      checkInInstructionsObj.CHECK_IN_INST_ORIENTATION,
      checkInInstructionsObj.CHECK_IN_INST_EARLYCHECKIN,
      checkInInstructionsObj.CHECK_IN_INST_FIRSTSHIFT,
      checkInInstructionsObj.CHECK_IN_INST_TIMECARD,
      checkInInstructionsObj.CHECK_IN_INST_DRESS,
      checkInInstructionsObj.CHECK_IN_INST_OTHER,
    ];
    return checkInInstructionsArr
      .filter(
        (instruction) =>
          instruction &&
          instruction.note &&
          instruction.note.trim() &&
          instruction.category &&
          instruction.category.trim()
      )
      .map((instruction) => stripVisibleToHCPSuffix(instruction));
  };

  const hasCheckInInstructionsPresent = (checkInInstructionsObj) => {
    return getPresentCheckInInstructions(checkInInstructionsObj).length > 0;
  };

  const shouldShowCheckInInstructions = (checkInInstructionsObj) => {
    return (
      checkInInstructionsObj &&
      (checkInInstructionsObj.CHECK_IN_INST ||
        hasCheckInInstructionsPresent(checkInInstructionsObj))
    );
  };

  const getCheckInInstructionsMap = (checkInInstructionsObj) => {
    const checkInInstructionsArr = {};
    if (
      !hasCheckInInstructionsPresent(checkInInstructionsObj) &&
      checkInInstructionsObj.CHECK_IN_INST
    ) {
      checkInInstructionsArr[
        stripVisibleToHCPSuffix(checkInInstructionsObj.CHECK_IN_INST).category
      ] = checkInInstructionsObj.CHECK_IN_INST.note;
    } else {
      getPresentCheckInInstructions(checkInInstructionsObj).forEach((instruction) => {
        checkInInstructionsArr[instruction.category] = instruction.note;
      });
    }
    return checkInInstructionsArr;
  };

  const getCheckInInstructionsMessage = (checkInInstructionsObj) => {
    if (shouldShowCheckInInstructions(checkInInstructionsObj)) {
      return getCheckInInstructionsMap(checkInInstructionsObj);
    }
    return null;
  };

  const { data: facilityNotesData, isLoading: isFacilityNotesLoading } = useFacilityNotes(
    facility.userId
  );
  const facilityNotes = facilityNotesData ?? [];

  const checkInInstructionsObj = getCheckInInstructionsObj(facilityNotes);
  const checkInInstructionsMessage = getCheckInInstructionsMessage(checkInInstructionsObj);

  const facilityDescription = getNotesByCategory(facilityNotes, FacilityNoteIdentifier.PROF_DESC);

  const mandatoryBreakPolicy = facilityNotes.find(isMandatoryBreakPolicyNote);
  const { data: requiresLunchBreakData } = useFacilityProfile({
    workplaceId: facility.userId,
    projection: "type requiresLunchBreak",
  });

  const isQuizWorkersOnWorkplaceRulesFeatureEnabled =
    useIsQuizWorkersOnWorkplaceRulesFeatureEnabled({
      workplace: {
        quizWorkersOnRules: facility?.quizWorkersOnRules,
      },
    });

  const { data: quizData } = useGetWorkplaceQuiz(facility.userId, {
    enabled: isQuizWorkersOnWorkplaceRulesFeatureEnabled,
  });
  const latestQuizData = quizData?.data?.[0];
  const facilityNotesForQuiz =
    quizData?.included?.map((facilityNote) => facilityNote.attributes) ?? [];
  const isWorkplaceRulesQuizEnabled =
    isQuizWorkersOnWorkplaceRulesFeatureEnabled &&
    isDefined(latestQuizData) &&
    latestQuizData.attributes.summary.length > 0 &&
    isDefined(facility.name);

  const isStreaksEnabledForHcp = useStreaksEnabledForHcp();
  const { hcpStreaksResponseData, isStreaksEnabledGlobally } = useHCPStreakData([facility.userId]);
  const { data: streaksSettings } = useGetStreaksSettings(
    { facilityId: facility.userId },
    {
      enabled: !isEmpty(facility.userId),
    }
  );

  const activePayBoostedStreaks = getCurrentActiveStreaks(
    getPayBoostActiveStreaks(hcpStreaksResponseData)
  );

  return (
    <IonPage>
      <PageWithHeader
        containerVariant="without-margin"
        appBarHeader={
          showHeader ? (
            <AppBarHeader
              title={facility.name || "Facility Details"}
              leftCta={
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="Close"
                  title="Close"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              }
            />
          ) : null
        }
      >
        <Box>
          <Box className="facility-photo" id="facility-street-img">
            {isLoadingPhotos || isLoadingFacilityStreetImgUrl ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  aspectRatio: "1/1",
                }}
              >
                <IonSpinner slot="start" name="lines" />
              </Box>
            ) : null}
            {isSuccessPhotos && !isLoadingFacilityStreetImgUrl ? (
              <FacilityPhotos
                facilityPhotos={facilityPhotos.photosList}
                facilityStreetImgUrl={facilityStreetImgUrl?.data.signedUrl ?? ""}
                facilityName={facility.name}
              />
            ) : null}
          </Box>

          {isDefined(workPlaceDetails) ? (
            <DeprecatedRatingsSummaryWorkplaceDetails
              workPlaceDetails={workPlaceDetails}
              screenName={deprecatedScreenName}
            />
          ) : null}
          <IonList lines="none" className="facility-profile">
            <IonItem lines="none" class="card-item-height">
              <IonLabel color="text" data-testid="facility-details">
                <h2 className="facility-name-title">{facility.name}</h2>
                <p className="ion-text-wrap">{facility?.fullAddress?.formatted}</p>
                <p>{formatPhone(facility?.phone as string, usCountyCode)}</p>
                <IonIcon icon={home} />
                {distanceInMiles !== -1 && (
                  <span>{` ~${distanceInMiles} ${pluralize(
                    "mile",
                    distanceInMiles
                  )} from home`}</span>
                )}

                {facilityTypeShiftIconsEnabled && (
                  <div>
                    <IonChip color="primary" className="small-chip">
                      {facility?.type}
                    </IonChip>
                  </div>
                )}
              </IonLabel>
              <IonButton
                style={{ fontSize: "16px", height: "80px" }}
                data-testid="maps-button"
                fill="clear"
                size="small"
                onClick={onMapsClick("overlay")}
              >
                <img alt="check-in-collapse" src={MapDirections} />
              </IonButton>
            </IonItem>
          </IonList>

          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                fill="outline"
                shape="round"
                strong={true}
                data-testid="open-shifts-button"
                className="shift-ion-button ion-button"
                onClick={() => {
                  facilityOpenShiftsRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                <div>
                  Open Shifts
                  <br />
                  <img alt="check-in-collapse" src={ChevronDown} />
                </div>
              </IonButton>
            </IonCol>
          </IonRow>

          {isStreaksEnabledGlobally &&
            (isStreaksEnabledForHcp || !isEmpty(activePayBoostedStreaks)) && (
              <FacilityStreakInfoCard
                streaks={getPayBoostActiveStreaks(hcpStreaksResponseData)}
                streaksSettings={streaksSettings?.data}
              />
            )}

          {isDefined(workPlaceDetails) ? (
            <ReviewSummaryWorkplaceDetails
              workPlaceDetails={workPlaceDetails}
              screenName={deprecatedScreenName}
            />
          ) : null}

          {isWorkplaceRulesQuizEnabled && isDefined(facility.name) && (
            <WorkplaceImportantInformation
              workplace={{ name: facility.name }}
              facilityNotes={facilityNotesForQuiz}
              quizData={latestQuizData}
            />
          )}

          {!isWorkplaceRulesQuizEnabled &&
            isDefined(facilityDescription?.note) &&
            !isFacilityNotesLoading && (
              <FacilityDetailsCollapse
                isExpandedByDefault
                disableToggle
                icon={<img alt="important-information" src={ExclamationMark} />}
                title="Important information"
                onToggle={onImportantInformationPanelClick}
              >
                <IonText>
                  <p className="card-section-body">
                    {" "}
                    {`As of ${moment(facilityDescription.updatedAt).format("MMM DD, YYYY")} `}{" "}
                  </p>
                </IonText>
                <IonText>
                  <p className="card-section-body">
                    {facilityDescription.note}
                    <br />
                    <br />
                    <i>{MessageString.importantInformationFromHCFDisclaimerText}</i>
                  </p>
                </IonText>
              </FacilityDetailsCollapse>
            )}

          {!isWorkplaceRulesQuizEnabled &&
            checkInInstructionsMessage &&
            !isFacilityNotesLoading && (
              <FacilityDetailsCollapse
                icon={<img alt="check-in" src={CheckedInInstruction} />}
                title="Check in instructions"
                onToggle={onCheckInInfoPanelClick}
              >
                <p className="card-section-body">
                  <ul>
                    {Object.keys(checkInInstructionsMessage).map((name) => (
                      <li key={name}>
                        <Text>
                          <b>{name.replace(STRING_TO_DELETE, "")}</b>:{" "}
                          <span>{checkInInstructionsMessage[name]}</span>
                        </Text>
                      </li>
                    ))}
                  </ul>
                </p>
              </FacilityDetailsCollapse>
            )}

          <FacilityDetailsCollapse
            icon={<TimerOutlinedIcon />}
            title="Extra Time Pay"
            onToggle={onExtraTimePayPanelClick}
            isExpandedByDefault={displayExtraTimePayCard}
          >
            {isDefined(facility.userId) && <ExtraTimePayCard facilityUserId={facility.userId} />}
          </FacilityDetailsCollapse>

          {isDefined(mandatoryBreakPolicy) && requiresLunchBreakData?.requiresLunchBreak && (
            <FacilityDetailsCollapse icon={<EventBusyIcon />} title="Mandatory breaks">
              <Stack>
                <Text paddingBottom={2} variant="caption">
                  As of {formatDate(mandatoryBreakPolicy.updatedAt ?? new Date())},
                </Text>
                <Text paddingBottom={2} variant="caption">
                  This facility requires a 30-minute unpaid break and enforces the following policy:
                </Text>
                {mandatoryBreakPolicy.note?.split("\n").map((chunk) => {
                  return (
                    <Text fontStyle="italic" key={chunk} paddingBottom={1} variant="caption">
                      {chunk}
                    </Text>
                  );
                })}
                <Text variant="caption" paddingTop={1}>
                  If you work through the break, you will have to submit additional documentation.
                  The payment for the additional worked time may be withheld for up to 3 business
                  days while the break payment request undergoes a separate approval process.
                </Text>
              </Stack>
            </FacilityDetailsCollapse>
          )}

          {showCancellationPayout && (
            <FacilityDetailsCollapse
              icon={<img alt="free cancellation" src={FreeCancellation} />}
              title="Cancellation Payouts"
              onToggle={onCancellationPayoutPanelClick}
            >
              <CancellationPayout facility={facility} />
            </FacilityDetailsCollapse>
          )}

          {overlay.length > 0 && openMapsOverlay()}

          <Box id="shifts-list" ref={facilityOpenShiftsRef} data-testid="shifts-list">
            <FacilityOpenShifts
              facility={facility}
              onClose={onClose}
              hcfProfileSource={hcfProfileSource}
              searchMode={searchMode}
            />
          </Box>
        </Box>
      </PageWithHeader>
    </IonPage>
  );
};

export { FacilityDetails };
